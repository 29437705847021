import { captureMessage } from '@sentry/nextjs';
import type { MutateOptions } from '@tanstack/react-query';
import { useMutation as useReactMutation } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';

import { endPoints } from './Routes/routes';
import { useUser } from './useUser';

// TODO: different way to call get-sums endpoint
export type ClapProxy = 'public' | 'general' | 'get-sums';
export type Method = 'OPTIONS' | 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';

interface ClapError {
  description?: string;
  errors: Record<string, string> | string[];
  fields?: Record<string, string>;
  type?: string;
}

type Mutation<TResult, TVariable> = MutateOptions<
  TResult | undefined,
  ReturnType<typeof parseError>,
  TVariable | undefined
>;

export interface MutationParams<TResult, TVariables> {
  method?: Method;
  excludedErrorTypes?: string[];
  errorMessage?: string;
  clapProxy?: ClapProxy;
  download?: { filename: string };
  onSuccess?: Mutation<TResult, TVariables>['onSuccess'];
  onError?: Mutation<TResult, TVariables>['onError'];
}

const parseError = (error: Error) => {
  try {
    const obj = JSON.parse(error.message);

    if (
      obj &&
      typeof obj === 'object' &&
      'message' in obj &&
      typeof obj.message === 'string' &&
      'type' in obj &&
      typeof obj.type === 'string'
    ) {
      return { message: obj.message, type: obj.type };
    }

    return { message: error.message, type: undefined };
  } catch {
    return { message: error.message, type: undefined };
  }
};

export const getCsrf = () =>
  document.querySelector("meta[name='csrf-token']")?.getAttribute('content') ?? '';

export const isJsonString = (str: string) => {
  try {
    const obj = JSON.parse(str);

    if (obj && typeof obj === 'object') {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

const isClapError = <T>(data: T | ClapError): data is ClapError =>
  (data as ClapError).errors !== undefined;

export const getMainUrl = (clapProxy?: ClapProxy) => {
  if (clapProxy === 'general') {
    return '/api/public/client';
  }

  return clapProxy ? `/api/client/${clapProxy}` : undefined;
};

export const useMutation = <
  TResult extends Record<string, unknown>,
  TVariables extends unknown[] | Record<string, unknown> = any,
>(
  {
    method = 'POST',
    clapProxy,
    errorMessage,
    excludedErrorTypes,
    onSuccess,
    onError,
    download,
  }: MutationParams<TResult, TVariables> = {
    method: 'POST',
  },
) => {
  const { user } = useUser();
  const router = useRouter();

  return useReactMutation<
    TResult | undefined,
    Error,
    { url: string; variables?: Record<string, unknown> | unknown[]; formValues?: TVariables }
  >({
    mutationFn: async inputData => {
      if (
        user.isLoggedIn &&
        !user.editEnabled &&
        inputData.url !== endPoints.logout &&
        clapProxy === 'public'
      ) {
        throw new Error('You do not have permission to perform this action');
      }
      const clapProxyUrl = getMainUrl(clapProxy);

      const getBody = () => {
        const getContent = () => {
          if (clapProxyUrl) {
            return {
              method,
              path: inputData.url,
              params: inputData.variables ?? null,
            };
          }

          return inputData.variables;
        };

        return JSON.stringify(getContent());
      };

      const response = await fetch(clapProxyUrl ?? inputData.url, {
        method: clapProxy ? 'POST' : method,
        cache: 'no-cache',
        redirect: 'follow',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          csrf: getCsrf(),
        },
        body: getBody(),
      });
      if (download && response.status < 400) {
        const blob = await response.blob();

        const link = document.createElement('a');
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', download.filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }

        return;
      }

      if (response.redirected) {
        void router.push(response.url);

        return;
      }

      if (response.status === 204) {
        return;
      }

      const data = (await response.json()) as TResult;

      if (isClapError(data)) {
        if (!Array.isArray(data.errors)) {
          throw new Error(Object.values(data.errors)[0]);
        }

        throw new Error(data.fields ? Object.values(data.fields)[0] : data.errors[0]);
      }

      if (response.status >= 400) {
        if (data.type && typeof data.type === 'string') {
          throw new Error(JSON.stringify({ message: data.message, type: data.type }));
        }

        if (data.error && typeof data.error === 'string') {
          throw new Error(data.error);
        }
        if (data.fields && typeof data.fields === 'object') {
          const [firstValue] = Object.values(data.fields);
          if (typeof firstValue === 'string') {
            throw new Error(firstValue);
          }
          if (Array.isArray(firstValue) && typeof firstValue[0] === 'string') {
            throw new Error(firstValue[0]);
          }
        }
        if (data.message && typeof data.message === 'string') {
          throw new Error(data.message);
        }

        captureMessage(`Unknown error ${response.status}: ${JSON.stringify(data)}`);
        throw new Error('Unknown Error');
      }

      return data;
    },
    onSuccess: (data, variables, context) => onSuccess?.(data, variables.formValues, context),
    onError: (error, variables, context) => {
      const parsedError = parseError(error);

      if (!parsedError.type || !excludedErrorTypes?.includes(parsedError.type)) {
        toast(errorMessage ?? parsedError.message, { type: 'error' });
      }

      onError?.(parsedError, variables.formValues, context);
    },
  });
};
