import React from 'react';

import type { IUserData } from '@lib/prefetchUserData';

export const DEFAULT_CDN_QUOTA = 50;

export const initialUserData: IUserData = {
  id: '',
  isLoggedIn: false,
  editEnabled: false,
  account_info: {
    customer_id: 0,
    email_address: '',
    email_confirmed: false,
    has_stream_resources: false,
    full_name: '',
    is_main_account: true,
    phone_number: '',
    has_read_storage_termination_notice: false,
    required_team_members_2fa: false,
  },
  billing: {
    external_billing: false,
    future_pricing_type: 'none',
    current_pricing_type: 'none',
    country_iso_code: '',
  },
  cdn_settings: {
    active_cdns_count_quota: DEFAULT_CDN_QUOTA,
  },
  configuration: {
    spending_stats_configuration: {
      has_daily_overview_visible: false,
      daily_overview_visible_from: null,
    },
    livestreaming_panel_enabled: false,
    two_factor_authentication_enabled: false,
    object_storage_enabled: false,
    real_time_log_enabled: false,
    paypal_enabled: true,
    tsunami_enabled: false,
  },
  is_billed_by_traffic: false,
  is_visible_daily_overview: false,
  is_visible_popular_objects: false,
  is_highwinds_enabled: false,
};

const UserContext = React.createContext<{
  user: IUserData;
  setUser: (user: IUserData) => void;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
}>({ user: initialUserData, setUser: () => {} });

export const useUser = () => React.useContext(UserContext);

export const UserProvider: React.FC<{
  userData: IUserData;
  children: JSX.Element | JSX.Element[];
}> = ({ userData, children }) => {
  const [user, setUser] = React.useState<IUserData>(userData ?? initialUserData);

  React.useEffect(() => {
    setUser(userData ?? initialUserData);
  }, [userData]);

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};
